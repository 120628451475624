const state = {
  email: null,
  password: null
}

const getters = {
  isLoggedIn ({ email, password }) {
    return email != null && password != null
  },
  getEmail ({ email }) {
    return email
  },
  getPassword ({ password }) {
    return password
  },
  getAuth ({ email, password }) {
    return { email, password }
  }
}

const mutations = {
  setAuth (state, { email, password }) {
    state.email = email
    state.password = password
  },
  clearAuth (state) {
    state.email = null
    state.password = null
  }
}

const actions = {}

export default {
  state,
  mutations,
  actions,
  getters
}
