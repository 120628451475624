const state = {
  from: null,
  to: null,
  real: null,
  voice: 'off',
  recording: 'off'
}

const getters = {
  getFrom ({ from }) {
    return from
  },
  getTo ({ to }) {
    return to
  },
  getReal ({ real }) {
    return real
  },
  getVoice ({ voice }) {
    return voice
  },
  getRecording ({ recording }) {
    return recording
  }
}

const mutations = {
  setPhones (state, { from, to, real, voice, recording }) {
    state.from = from
    state.to = to
    state.real = real
    state.voice = voice
    state.recording = recording
  },
  clearPhones (state) {
    state.from = null
    state.to = null
    state.real = null
    state.voice = 'off'
    state.recording = 'off'
  }
}

const actions = {}

export default {
  state,
  mutations,
  actions,
  getters
}
