import Vue from 'vue'
import { Store, install } from 'vuex'
import { mergeDeep } from '@/utils/primitives'
import modules from './modules'
import plugins from './plugins'

Vue.use({ Store, install })

let initialState

// if (process.env.NODE_ENV !== 'production') {
//   const params = new URLSearchParams(location.search)
//   if (params.has('state')) {
//     initialState = require(`../__fixtures__/${params.get('state')}`)
//   }
// }

export default new Store({
  state: mergeDeep({}, initialState),
  modules,
  plugins
})
