<template>
  <!-- Need id="app" to properly attach on prerendered-->
  <router-view id="app"></router-view>
</template>

<script>
export default {
  name: 'app',
  mounted () {
    const params = new URLSearchParams(location.search)
    const message = params.get('msg')
    if (message) {
      this.$store.commit('setMessage', { message })
    }
  }
}
</script>

<style lang="scss">
:root, html, body {
  margin: 0;
  padding: 0;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, Roboto, "Helvetica Neue", Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

em {
  color: $primary;
  font: inherit;
  text-decoration: inherit;
}

@include typi-init;

.hidden {
  display: none;
}
</style>
