import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import store from './store'
import router from './router'
import VueClipboard from 'vue-clipboard2'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueMobileDetection from 'vue-mobile-detection'

Vue.use(VueMobileDetection)

Vue.use(VueMeta)

Vue.config.silent = process.env.NODE_ENV === 'production'

router.beforeEach((to, from, next) => {
  store.dispatch('resetCallout')
  store.commit('clearMessage')
  document.title = to.meta.title
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isLoggedIn) {
    next({
      name: 'login',
      query: { redirect: to.fullPath }
    })
  } else if (to.matched.some(record => record.meta.requireGuest) && store.getters.isLoggedIn) {
    next({
      name: 'home'
    })
  } else {
    next() // make sure to always call next()!
  }
})

router.afterEach(() => {
  setTimeout(() => {
    window.scrollTop = 0
    if (document) {
      if (document.body) {
        document.body.scrollTop = 0
      }
      if (document.documentElement) {
        document.documentElement.scrollTop = 0
      }
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0
      }
    }
  })
})

Vue.use(VueClipboard)

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY })

Vue.use(Loading)

new Vue({
  render: h => h(App),
  router,
  store,
  // This is required for prerenderer to know when rendering have finished
  mounted: () => setTimeout(() => document.dispatchEvent(new Event('x-app-rendered')))
}).$mount('#app')
