import loadFromStorage from './_loadFromStorage'
import saveToStorage from './_saveToStorage'

export default (
  {
    key,
    module,
    filter,
    reducer = (i) => i
  }
) => (store) => {
  loadFromStorage(key, (newValue) => {
    const { [module]: oldValue, ...state } = store.state
    store.replaceState({
      ...state,
      [module]: {
        ...oldValue,
        ...newValue
      }
    })
  })

  if (typeof filter === 'string') {
    filter = ((value) => (mutation) => mutation.type === value)(filter)
  } else if (Array.isArray(filter)) {
    filter = ((values) => (mutation) => values.indexOf(mutation.type) !== -1)(filter)
  } else if (filter == null) {
    filter = () => true
  } else if (typeof filter !== 'function') {
    throw new Error(`Unsupported filter type ${typeof filter} - ${JSON.stringify(filter)}`)
  }

  store.subscribe((mutation, state) => {
    if (!filter(mutation, state)) return
    saveToStorage(key, reducer(state[module]))
  })
}
