import auth from './persistAuth'
import info from './persistInfo'
import form from './persistForm'
import billing from './persistBilling'
import login from './persistLogin'

export default [
  auth,
  info,
  form,
  billing,
  login
]
