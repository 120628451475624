import stripHtml from '../../utils/stripHtml'

const state = {
  message: null
}

const getters = {
  getMessage ({ message }) { return message }
}

const mutations = {
  clearMessage (state) {
    state.message = null
  },
  setMessage (state, { message }) {
    state.message = stripHtml(message)
  }
}

export default {
  state,
  mutations,
  getters
}
