import PRERENDER from './prerender'

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/PageHome'),
    meta: {
      title: 'Spoof Call And Caller ID Faker. Free Spoof Call To Try Now.',
      description: 'Spoof Call to change your CALLER ID when calling. Add voice changer for ultimate privacy. Try our free spoof call on this page to protect your personal information when on the phone.'
    }
  },
  {
    path: '/anonymous-texting',
    name: 'anonymous-texting',
    component: () => import('../pages/static/PageAnonymousTexting'),
    meta: {
      title: 'Anonymous Texting'
    }
  },
  {
    path: '/receive-calls',
    name: 'receive-calls',
    component: () => import('../pages/static/PageLinkdial'),
    meta: {
      title: 'linkdial'
    }
  },
  {
    path: '/call-success',
    name: 'call-success',
    component: () => import('@/pages/call/PageCallSuccess'),
    meta: {
      requiresAuth: true,
      title: 'Call Success'
    }
  },
  {
    path: '/recording-logs',
    name: 'recording-logs',
    component: () => import('@/pages/PageRecordingLogs'),
    meta: {
      requiresAuth: true,
      title: 'Recording logs'
    }
  },
  {
    path: '/spoof-call',
    name: 'spoof-call',
    component: () => import('@/pages/PageSpoofCall'),
    meta: {
      title: 'Spoof Call'
    }
  },
  {
    path: '/bridge-call',
    name: 'bridge-call',
    component: () => import('@/pages/PageBridgeCall'),
    meta: {
      title: 'Bridge Call'
    }
  },
  // AUTH
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/auth/PageLogin'),
    props: (route) => ({ redirect: route.query.redirect }),
    meta: {
      requireGuest: true
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../pages/auth/PageForgotPassword')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/auth/PageRegister')
  },
  // Static
  {
    path: '/refund',
    name: 'refund',
    component: () => import('../pages/static/PageRefund'),
    meta: {
      title: 'Refund Policy'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../pages/static/PageTerms'),
    meta: {
      title: 'Terms'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../pages/static/PagePrivacy'),
    meta: {
      title: 'Privacy'
    }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../pages/static/PageFeedback'),
    meta: {
      title: 'Contact Us'
    }
  },
  {
    path: '/free-credits',
    name: 'free-credits',
    component: () => import('../pages/static/PageFreeCredits'),
    meta: {
      title: 'Free Credits'
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../pages/static/PagePricing'),
    meta: {
      title: 'Pricing'
    }
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    component: () => import('../pages/static/PageAffiliate'),
    meta: {
      title: 'Affiliate'
    }
  },
  {
    path: '/number-block',
    name: 'number-block',
    component: () => import('../pages/PageBlockNumber'),
    meta: {
      title: 'Block Number'
    }
  },
  // Protected
  {
    path: '/call',
    name: 'call',
    component: () => import('@/pages/PageVoiceChanger'),
    meta: {
      requiresAuth: true,
      title: 'Spoof Call'
    }
  },
  {
    path: '/credits',
    name: 'credits',
    component: () => import('@/pages/PageCredits'),
    meta: {
      requiresAuth: true,
      title: 'Buy Credits'
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/pages/PageCheckout'),
    meta: {
      requiresAuth: true,
      title: 'Checkout'
    }
  },
  {
    path: '/switchere-checkout',
    name: 'switchere-checkout',
    component: () => import('@/pages/switchere/PageSwitchereCheckout'),
    meta: {
      requiresAuth: true,
      title: 'Switchere Checkout'
    }
  },
  {
    path: '/switchere-order',
    name: 'switchere-order',
    component: () => import('@/pages/switchere/PageSwitchereOrder'),
    meta: {
      requiresAuth: true,
      title: 'Switchere Order'
    }
  },
  {
    path: '/bitcoin-checkout',
    name: 'bitcoin-checkout',
    component: () => import('@/pages/bitcoin/PageBitcoinCheckout'),
    meta: {
      requiresAuth: true,
      title: 'Bitcoin Checkout'
    }
  },
  {
    path: '/bitcoin-pay',
    name: 'bitcoin-pay',
    component: () => import('@/pages/bitcoin/PageBitcoinPay'),
    meta: {
      requiresAuth: true,
      title: 'Bitcoin Pay'
    }
  },
  {
    path: '/credit-card-checkout',
    name: 'credit-card-checkout',
    component: () => import('@/pages/creditcard/PageCreditCardCheckout'),
    meta: {
      requiresAuth: true,
      title: 'Credit Card Checkout'
    }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('@/pages/settings/PagePassword'),
    meta: {
      requiresAuth: true,
      title: 'Change Password'
    }
  },
  {
    path: '/email',
    name: 'email',
    component: () => import('@/pages/settings/PageChangeEmail'),
    meta: {
      requiresAuth: true,
      title: 'Change Email'
    }
  },
  // anonymous texting
  {
    path: '/sms',
    name: 'conversations',
    component: () => import('@/pages/anonymousTexting/PageConversations'),
    meta: {
      requiresAuth: true,
      title: 'Conversations'
    }
  },
  {
    path: '/sms/new',
    name: 'new-conversation',
    component: () => import('@/pages/anonymousTexting/PageNewConversation'),
    meta: {
      requiresAuth: true,
      title: 'New Message'
    }
  },
  // must be after the new message route
  {
    path: '/sms/:target',
    name: 'messages',
    component: () => import('@/pages/anonymousTexting/PageMessages'),
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Messages'
    }
  },
  {
    path: '/sms/:target/new',
    name: 'new-message',
    component: () => import('@/pages/anonymousTexting/PageNewConversation'),
    props: true,
    meta: {
      requiresAuth: true,
      title: 'New Message'
    }
  },
  // other
  {
    path: '/verify',
    name: 'verify',
    component: () => import('@/pages/settings/PageVerify'),
    meta: {
      title: 'Verify'
    }
  },
  {
    path: '/access-denied',
    name: 'Access Denied',
    component: () => import('../pages/static/PageAccessDenied'),
    meta: {
      title: 'Access Denied | This website does not operate in your country.'
    }
  },
  ...PRERENDER,
  // Default
  {
    path: '*',
    redirect: '/',
    meta: {
      title: 'Spoof Call And Caller ID Faker. Free Spoof Call To Try Now.',
      description: 'Spoof Call to change your CALLER ID when calling. Add voice changer for ultimate privacy. Try our free spoof call on this page to protect your personal information when on the phone.'
    }
  }
]
