/**
 * Check for undefined
 * @param value
 * @return {boolean}
 */
export const isUndefined = (value) => typeof value === 'undefined'

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = (item) => item && typeof item === 'object' && !Array.isArray(item)

/**
 * Deep merge two objects.
 * @param {object} target
 * @param {object[]} sources
 * @return {object}
 */
export const mergeDeep = (target, ...sources) => {
  if (!isObject(target)) throw new Error(`can't merge into non-object`)
  for (const source in sources) {
    if (!isObject(source)) continue

    for (const key in source) {
      if (!source.hasOwnProperty(key)) continue
      const value = source[key]

      // skip undefined values
      if (isUndefined(value)) continue

      target[key] =
        isObject(value)
          ? mergeDeep(key in target ? target[key] : {}, value)
          : value
    }
  }

  return target
}

/**
 * @param {*} a
 * @param {*} b
 * @return {boolean}
 */
export const deepEqual = (a, b) => {
  if (a === b) return true

  if (
    a instanceof Date &&
    b instanceof Date &&
    a.getTime() !== b.getTime()
  ) {
    // If the values are Date, compare them as timestamps
    return false
  }

  if (a !== Object(a) || b !== Object(b)) {
    // If the values aren't objects, they were already checked for equality
    return false
  }

  const props = Object.keys(a)

  if (props.length !== Object.keys(b).length) {
    // Different number of props, don't bother to check
    return false
  }

  return props.every(p => deepEqual(a[p], b[p]))
}

/**
 * Returns the input if it's array otherwise creates array with first item the input
 * @param {*|Array} v
 * @return {Array}
 */
export const wrapInArray = (v) => v != null ? Array.isArray(v) ? v : [v] : []
