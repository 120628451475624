import localStorage from './_localStorage'

export default (key, callback, { storage = localStorage } = {}) => {
  const value = storage.getItem(key)
  if (value != null) {
    try {
      callback(JSON.parse(value))
    } catch (e) {
      console.warn('Invalid JSON', value, e)
    }
  }
}
