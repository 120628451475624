import stripHtml from '../../utils/stripHtml'

const DEFAULT_CALLOUT = 'spoof call and caller id faker,\nfree spoof call to try'

export default {
  state: {
    callout: DEFAULT_CALLOUT
  },
  getters: {
    hasCallout ({ callout }) {
      return callout != null
    },
    getCalloutMessage ({ callout }) {
      return stripHtml(callout)
    }
  },
  mutations: {
    setCallout (state, { message }) {
      state.callout = message
    },
    setAdminMessage (state, { message }) {
      if (state.callout === DEFAULT_CALLOUT) {
        state.callout = message
      }
      state.adminMessage = message
    },
    resetCallout (state) {
      state.callout = state.adminMessage || DEFAULT_CALLOUT
    }
  },
  actions: {
    callout ({ commit }, { message }) {
      commit('setCallout', { message })
    },
    resetCallout ({ commit }) {
      commit('resetCallout')
    }
  }
}
