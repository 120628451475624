import Vue from 'vue'
import VueMeta from 'vue-meta'
import Router from 'vue-router'

import routes from './routes'

Vue.use(Router)
Vue.use(VueMeta)

export default new Router({
  base: `${process.env.VUE_APP_PUBLIC_PATH}/`,
  routes,
  mode: 'history',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact-active'
})
