const state = {
  firstName: null,
  lastName: null,
  city: null,
  address: null,
  zip: null,
  region: null,
  phone: null
}

const getters = {
  getFirstName ({ firstName }) {
    return firstName
  },
  getLastName ({ lastName }) {
    return lastName
  },
  getCity ({ city }) {
    return city
  },
  getAddress ({ address }) {
    return address
  },
  getZip ({ zip }) {
    return zip
  },
  getRegion ({ region }) {
    if (region === null) {
      return ''
    }
    return region
  },
  getPhone ({ phone }) {
    return phone
  }
}

const mutations = {
  setBilling (state, { firstName, lastName, city, address, zip, region, phone }) {
    state.firstName = firstName
    state.lastName = lastName
    state.city = city
    state.address = address
    state.zip = zip
    state.region = region
    state.phone = phone
  },
  clearBilling (state) {
    state.firstName = null
    state.lastName = null
    state.city = null
    state.address = null
    state.zip = null
    state.region = null
    state.phone = null
  }
}

const actions = {}

export default {
  state,
  mutations,
  actions,
  getters
}
