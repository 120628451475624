const state = {
  message: null,
  type: null
}

const getters = {
  hasToast ({ message }) {
    return message != null
  },
  toast ({ message, type }) {
    return { message, type }
  }
}

const mutations = {
  add (state, { message, type }) {
    state.message = message
    state.type = type
  },
  clear (state) {
    state.message = null
    state.type = null
  }
}

const actions = {
  show ({ commit }, payload) {
    commit('add', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
