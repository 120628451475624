const state = {
  page: null
}

const getters = {
  getPage ({ page }) {
    return page
  }
}

const mutations = {
  setLogin (state, { page }) {
    state.page = page
  },
  clearLogin (state) {
    state.page = null
  }
}

const actions = {}

export default {
  state,
  mutations,
  actions,
  getters
}
